import gql from "graphql-tag";

const GET_MSC_BLACKLISTED_RESOURCES = gql`
  query MscBlacklistResources(
    $filters: ManagedServicesConnectorBlacklistedResourcesInput!
  ) {
    finance {
      managedServicesConnector {
        blacklistedResources(filters: $filters) {
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
          result {
            categoryInfo {
              category
              scale
              subCategories {
                scale
                value
              }
            }
            identifiers
          }
        }
      }
    }
  }
`;

export { GET_MSC_BLACKLISTED_RESOURCES };
