import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import BlacklistedResourcesView from "../component/BlacklistedResourcesView.vue";

export default {
  components: {
    BlacklistedResourcesView: BlacklistedResourcesView
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Group"
      },
      {
        title: "Navigation Menu Group",
        route: "/group/navigation-menu"
      },
      {
        title: "Blacklisted Resources",
        route: "/group/managed-services-connector/blacklisted-resources"
      },
      {
        title: "Pre-Storage"
      }
    ]);
  }
};
