import { render, staticRenderFns } from "./PreStorage.vue?vue&type=template&id=0212673b&"
import script from "./PreStorage.js?vue&type=script&lang=js&"
export * from "./PreStorage.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports