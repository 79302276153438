import NotificationService from "@/core/services/notification.service";
import LocalStorageService from "@/core/services/localstorage.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import {
  CLOUD_PROVIDER_ENUM,
  COUNTRY_ENUM,
  MSC_RESOURCE_ACTION_ENUM,
  MSC_SCALE_ENUM
} from "@/core/constants";
import { GET_MSC_BLACKLISTED_RESOURCES } from "../graphql/blacklisted_resources.queries";
import { MUTATION_MSC_BLACKLISTED_RESOURCES } from "../graphql/blacklisted_resources.mutations";
import DialogService from "@/core/services/dialog.service";
import dayjs from "dayjs";
import { GET_MSC_CUSTOMERS } from "../../customers/graphql/customers.queries";
//#region Vee Validation
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} is required"
});
//#endregion

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    postRetrieve: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      skipQuery: true,
      categorySearch: "",
      editMode: false,
      deleteButtonClicked: false,
      dataLoaded: false,
      dialogCategoryForm: false,
      dialogSubCategoryForm: false,
      dialogIdentifierForm: false,
      subCategoryNameValueInput: "",
      cloudProviderList: [
        { name: CLOUD_PROVIDER_ENUM.AWS },
        { name: CLOUD_PROVIDER_ENUM.AZURE }
      ],
      countryList: Object.entries(COUNTRY_ENUM).map(([name]) => ({
        name
      })),
      selectedCloudProvider: null,
      selectedCountry: null,
      customerList: [],
      selectedCustomer: null,
      selectedCategory: {},
      subCategoriesList: [],
      selectedSubCategories: [],
      categoryTableHeaders: [
        { text: "Category", value: "category" },
        { text: "SubCategories", value: "subCategories" },
        { text: "Scale", value: "scale" },
        { text: "Actions", value: "actions", sortable: false, width: "130px" }
      ],
      dialogTableHeaders: [
        { text: "Name", value: "name" },
        { text: "Scale", value: "scale" }
      ],
      categoryInfo: [],
      identifierSearch: "",
      originalIdentifierList: [],
      identifierList: [],
      selectedIdentifiers: [],
      newIdentifierModel: null
    };
  },
  apollo: {
    customers: {
      client: "connectClient",
      query: GET_MSC_CUSTOMERS,
      variables() {
        return this.getCustomersQueryVariable();
      },
      update(data) {
        let customers = data.finance.managedServicesConnector.customers;
        if (!customers.result) {
          NotificationService.error(
            `${customers.metadata.message} (${customers.metadata.statusCode})`
          );
          return [];
        } else {
          this.customerList = customers.result;
        }

        LocalStorageService.setItem(
          this.getCustomerLocalStorageKey(),
          this.customerList
        );
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.customers.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      }
    },
    blacklistedResources: {
      client: "connectClient",
      query: GET_MSC_BLACKLISTED_RESOURCES,
      variables() {
        return this.getMscBlacklistedResourcesQueryVariable();
      },
      update(data) {
        this.dataLoaded = true;
        let resources =
          data.finance.managedServicesConnector.blacklistedResources;
        if (!resources.result) {
          NotificationService.error(
            `${resources.metadata.message} (${resources.metadata.statusCode})`
          );
          return [];
        } else {
          this.categoryInfo = resources.result?.categoryInfo;
          this.identifierList = resources.result?.identifiers.select(x => {
            return { identifier: x, scale: "CUSTOMER" };
          });
        }

        this.deepCloneIdentifierToOriginalList(this.identifierList);
        return resources.result;
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.blacklistedResources.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  },
  methods: {
    getIdentifierTableHeaders() {
      return [
        { text: this.getIdentifierCardHeader(), value: "identifier" },
        { text: "Scale", value: "scale" }
      ];
    },
    getCustomerLocalStorageKey() {
      return `${this.selectedCloudProvider.name}.${this.selectedCountry.name}.${MSC_SCALE_ENUM.CUSTOMER}`;
    },
    customFilter(item, search, filter) {
      search = search.toString().toLowerCase();
      if ("all".indexOf(search) > -1 && filter.subCategories.length === 0) {
        return true;
      }

      return Object.values(filter).some(
        v => v && v.toString().toLowerCase().includes(search)
      );
    },
    getCustomersQueryVariable() {
      return {
        filters: {
          addDetails: {
            value: [false]
          },
          cloudProvider: {
            value: [this.selectedCloudProvider.name]
          },
          country: {
            value: [this.selectedCountry.name]
          }
        }
      };
    },
    getMscBlacklistedResourcesQueryVariable() {
      let filterOptions = {
        cloudProvider: {
          value: [this.selectedCloudProvider.name]
        },
        postRetrieve: {
          value: [this.postRetrieve]
        }
      };

      if (this.selectedCountry) {
        filterOptions["country"] = {
          value: [this.selectedCountry.name]
        };
      }

      if (this.selectedCustomer) {
        filterOptions["customerAccountNumber"] = {
          value: [this.selectedCustomer.accountNumber]
        };
      }

      return {
        filters: filterOptions
      };
    },
    loadMscBlacklistedResources() {
      this.$apollo.queries.blacklistedResources.skip = false;
      this.$apollo.queries.blacklistedResources.refetch();
    },
    cloudProviderSelectionChanged() {
      this.selectedCountry = null;
      this.customerList = [];
      this.selectedCustomer = null;
      this.blacklistedResources = [];
      this.categoryInfo = [];
      this.identifierList = [];
    },
    countrySelectionChanged() {
      if (this.selectedCloudProvider && this.selectedCountry) {
        this.customerList = [];
        this.blacklistedResources = [];
        this.categoryInfo = [];
        this.identifierList = [];
        this.selectedCustomer = null;

        let blacklistedCustomers = LocalStorageService.getItem(
          this.getCustomerLocalStorageKey()
        );

        if (blacklistedCustomers) {
          this.customerList = blacklistedCustomers;
        }
        this.$apollo.queries.customers.skip = false;
        this.$apollo.queries.customers.refetch();
      }
    },
    customerSelectionChanged() {
      this.blacklistedResources = [];
      this.categoryInfo = [];
      this.identifierList = [];
    },
    async callMutationBlacklistedResources(message, graphqlCalls) {
      if (
        await DialogService.confirm(this, "Confirmation", message, {
          manualControl: true
        })
      ) {
        let results = await Promise.all(
          graphqlCalls.map(async item => {
            return await this.$apollo.mutate({
              client: "connectClient",
              mutation: MUTATION_MSC_BLACKLISTED_RESOURCES,
              variables: {
                input: {
                  action: item.action,
                  cloudProvider: item.cloudProvider,
                  country: item.country,
                  customerAccountNumber: item.customerAccountNumber,
                  category: item.category,
                  subCategories: item.subCategories,
                  identifiers: item.identifiers,
                  postRetrieve: this.postRetrieve
                }
              }
            });
          })
        );

        if (this.checkGraphqlCallsSucceeded(results)) {
          this.loadMscBlacklistedResources();

          DialogService.close(this);
          this.dialogCategoryForm = false;
          setTimeout(() => {
            NotificationService.success("Successfully submitted your change");
          }, 100);
        }
      }
    },
    getSelectedSubCategoriesInfo() {
      return Array.from(this.selectedSubCategories, x => x.name);
    },
    tableEditButtonClick(item) {
      this.deleteButtonClicked = false;
      this.editMode = true;
      this.selectedCategory = item;
      this.subCategoriesList = this.selectedCategory.subCategories.map(item => {
        return {
          name: item.value,
          scale: item.scale,
          isSelectable: this.isItemSelectable(item)
        };
      });
      this.selectedSubCategories = [];
      this.dialogCategoryForm = true;
    },
    isItemSelectable(item) {
      if (this.selectedCustomer) {
        if (
          item.scale === MSC_SCALE_ENUM.COUNTRY ||
          item.scale === MSC_SCALE_ENUM.GLOBAL
        ) {
          return false;
        }
      } else if (this.selectedCountry) {
        if (item.scale === MSC_SCALE_ENUM.GLOBAL) {
          return false;
        }
      }

      return true;
    },
    disableEditButton(item) {
      if (this.selectedCustomer) {
        let customerSubCategoryExist = item.subCategories.any(
          x => x.scale === MSC_SCALE_ENUM.CUSTOMER
        );

        if (
          item.scale !== MSC_SCALE_ENUM.CUSTOMER &&
          !customerSubCategoryExist
        ) {
          return true;
        }
      } else if (this.selectedCountry) {
        let countrySubCategoryExist = item.subCategories.any(
          x => x.scale === MSC_SCALE_ENUM.COUNTRY
        );

        if (item.scale !== MSC_SCALE_ENUM.COUNTRY && !countrySubCategoryExist) {
          return true;
        }
      }

      return false;
    },
    getGraphqlCallsForCategoryChanges() {
      let latestItems = Array.from(this.subCategoriesList, x => x.name);
      let itemsToAdd = latestItems.filter(
        x => !this.selectedCategory.subCategories.find(s => s.value === x)
      );

      let itemsToRemove = this.selectedCategory.subCategories.filter(
        x => !latestItems.includes(x.value)
      );

      let itemsToRemoveGrouped = itemsToRemove.groupBy(x => x.scale);
      let graphqlRequests = [];
      if (itemsToRemoveGrouped.any()) {
        itemsToRemoveGrouped.forEach(item => {
          let variables = {
            action: MSC_RESOURCE_ACTION_ENUM.DELETE,
            cloudProvider: this.selectedCloudProvider.name,
            category: this.selectedCategory?.category ?? null,
            subCategories: item.map(m => m.value),
            postRetrieve: this.postRetrieve
          };

          variables = this.extendVariablesBasedOnScale(item.key, variables);
          graphqlRequests.push(variables);
        });
      }

      if (itemsToAdd.any() || !this.editMode) {
        graphqlRequests.push({
          action: MSC_RESOURCE_ACTION_ENUM.ADD,
          cloudProvider: this.selectedCloudProvider.name,
          country: this.selectedCountry?.name ?? null,
          customerAccountNumber: this.selectedCustomer?.accountNumber ?? null,
          category: this.selectedCategory?.category ?? null,
          subCategories: itemsToAdd,
          identifiers: null,
          postRetrieve: this.postRetrieve
        });
      }
      return graphqlRequests;
    },
    async dialogCategorySaveButtonClick() {
      let graphqlCalls = this.getGraphqlCallsForCategoryChanges();
      let modeMessage = this.editMode ? "update" : "create";
      let message = `Are you sure you want to ${modeMessage} the item <b>${
        this.selectedCategory.category
      }</b>?</br></br>
      ${this.getWarningMessageBasedOnOptions()}`;

      await this.callMutationBlacklistedResources(message, graphqlCalls);
    },
    checkGraphqlCallsSucceeded(results) {
      let callsSucceeded = false;
      for (const result of results) {
        try {
          if (
            result.data.managedServicesConnectorBlacklistedResourceUpdate
              .metadata.conversationId
          ) {
            callsSucceeded = true;
          }
        } catch (error) {
          callsSucceeded = false;
          break;
        }
      }
      return callsSucceeded;
    },
    async dialogCategoryDeleteButtonClick() {
      this.deleteButtonClicked = true;

      let graphqlRequests = [];
      let scale = this.getScaleBasedOnOptions();
      let subCategories = this.selectedCategory.subCategories.filter(
        x => x.scale === scale
      );

      let variables = {
        action: MSC_RESOURCE_ACTION_ENUM.DELETE,
        cloudProvider: this.selectedCloudProvider.name,
        category: this.selectedCategory?.category ?? null,
        subCategories: Array.from(subCategories, x => x.value)
      };

      variables = this.extendVariablesBasedOnScale(scale, variables);

      graphqlRequests.push(variables);
      let message = `Are you sure you want to delete the category <b>${
        this.selectedCategory.category
      }</b>?</br></br>
      ${this.getWarningMessageBasedOnOptions()}`;

      await this.callMutationBlacklistedResources(message, graphqlRequests);
    },
    newCategoryButtonClick() {
      this.selectedCategory = {
        subCategories: []
      };
      this.subCategoriesList = [];
      this.editMode = false;
      this.dialogCategoryForm = true;
      if (this.$refs.categoryForm) {
        this.$refs.categoryForm.reset();
      }
    },
    dialogCategoryCloseButtonClick() {
      this.dialogCategoryForm = false;
    },
    dialogCategoryNewButtonClick() {
      this.dialogSubCategoryForm = true;
      if (this.$refs.subCategoryForm) {
        this.$refs.subCategoryForm.reset();
      }
    },
    dialogCategoryRemoveAllButtonClick() {
      this.selectedSubCategories.forEach(item => {
        let itemIndex = this.subCategoriesList.indexOf(item);
        this.subCategoriesList.splice(itemIndex, 1);
      });

      this.selectedSubCategories = [];
    },
    dialogSubCategoryCancelButtonClick() {
      this.dialogSubCategoryForm = false;
    },
    dialogSubCategorySaveButtonClick() {
      this.dialogSubCategoryForm = false;
      this.subCategoriesList.push({
        name: this.subCategoryNameValueInput,
        scale: this.getScaleBasedOnOptions()
      });
    },
    extendVariablesBasedOnScale(scale, variables) {
      if (scale === MSC_SCALE_ENUM.GLOBAL) {
        return variables;
      } else if (scale === MSC_SCALE_ENUM.COUNTRY) {
        variables["country"] = this.selectedCountry?.name ?? null;
      } else if (scale === MSC_SCALE_ENUM.CUSTOMER) {
        variables["country"] = this.selectedCountry?.name ?? null;
        variables["customerAccountNumber"] =
          this.selectedCustomer?.accountNumber ?? null;
      }

      return variables;
    },
    getScaleBasedOnOptions() {
      if (this.selectedCustomer) {
        return MSC_SCALE_ENUM.CUSTOMER;
      }

      if (this.selectedCountry) {
        return MSC_SCALE_ENUM.COUNTRY;
      }

      if (this.selectedCloudProvider) {
        return MSC_SCALE_ENUM.GLOBAL;
      }
    },
    getWarningMessageBasedOnOptions() {
      if (this.selectedCustomer) {
        return "";
      }

      if (this.selectedCountry) {
        return `WARNING: This change will apply only to ALL customers in <b>${this.selectedCountry.name}</b>`;
      }

      if (this.selectedCloudProvider) {
        return "WARNING: This change will apply to ALL customers";
      }
    },
    getIdentifierCardHeader() {
      if (this.selectedCloudProvider?.name === CLOUD_PROVIDER_ENUM.AZURE) {
        return "Resource ID";
      } else if (this.selectedCloudProvider?.name === CLOUD_PROVIDER_ENUM.AWS) {
        return "ARN";
      }

      return "Identifiers";
    },
    getIdentifierHint() {
      if (this.selectedCloudProvider?.name === CLOUD_PROVIDER_ENUM.AZURE) {
        return "e.g. /subscriptions/<subscription_id>/resourceGroups/<resource_group_name>/*";
      } else if (this.selectedCloudProvider?.name === CLOUD_PROVIDER_ENUM.AWS) {
        return "e.g. arn:aws:ec2:eu-west-1:<account_id>:*";
      }

      return null;
    },
    identifierRemoveAllButtonClick() {
      this.selectedIdentifiers.forEach(item => {
        let itemIndex = this.identifierList.indexOf(item);
        this.identifierList.splice(itemIndex, 1);
      });

      this.selectedIdentifiers = [];
    },
    identifierNewTextFieldKeyEnterUp() {
      if (this.newIdentifierModel) {
        const identifierExist = this.identifierList.any(
          x => x.identifier === this.newIdentifierModel
        );
        if (identifierExist) {
          NotificationService.error("The identifier already exist in the list");
        } else {
          this.identifierList.push({
            identifier: this.newIdentifierModel,
            scale: MSC_SCALE_ENUM.CUSTOMER,
            isNew: true
          });
          this.newIdentifierModel = null;
        }
      }
    },
    async identifierSaveChangesButtonClick() {
      const graphqlCalls = this.getGraphqlCallsForIdentifierChanges();

      await this.callMutationBlacklistedResources(
        "Are you sure you want to submit your changes?",
        graphqlCalls
      );
    },
    getGraphqlCallsForIdentifierChanges() {
      let latestItems = this.identifierList.select(x => x.identifier);

      const graphqlRequests = [
        {
          action: MSC_RESOURCE_ACTION_ENUM.ADD,
          cloudProvider: this.selectedCloudProvider.name,
          country: this.selectedCountry?.name ?? null,
          customerAccountNumber: this.selectedCustomer?.accountNumber ?? null,
          identifiers: latestItems,
          postRetrieve: this.postRetrieve
        }
      ];

      return graphqlRequests;
    },
    deepCloneIdentifierToOriginalList(data) {
      this.originalIdentifierList = JSON.parse(JSON.stringify(data));
    },
    isIdentifierDataModified() {
      if (this.originalIdentifierList.count() === this.identifierList.count()) {
        const isValueChanged = this.originalIdentifierList.any(
          x => !this.identifierList.any(c => c.identifier === x.identifier)
        );

        return isValueChanged;
      }

      return true;
    }
  }
};
